import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  getPageType() {
    // @ts-ignore
    return this.vm?.pageType
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        {
          label: `page.type.${this.getPageType()}`,
          route: { name: `page-${this.getPageType()}` }
        },
      ],
      listRoute: () => ({ name: `page-${this.getPageType()}` }),
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_PAGE']),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['title']),
      defaultData: this._defaultData,
      blocks: [
        {
          data: {
            banner_id: {
              label: 'data.banner',
              type: 'banner',
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },
        {
          title: 'form_block.base',
          data: {
            title: {
              required: true,
              label: 'data.title',
              type: 'text',
              maxlength: 100,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            code: {
              required: true,
              label: 'page.data.code',
              type: 'text',
              randomUid: true,
              maxlength: 100,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            keywords: {
              label: 'data.keywords',
              help: 'keyword.help',
              type: 'text',
              maxlength: 200,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            categories: {
              label: 'data.categories',
              type: 'selection',
              optionMeta: item => ({ depth: item.depth }),
              itemDisabled: (item, formMeta) => {
                if(!formMeta) return false
                if(!formMeta.depthOfType) return false
                return item.depth != formMeta.depthOfType
              },
              optionText: (itemName, item: AnyObject) => this.vm?.$helper.nestedSetNodeNamePrefix(item.name, item.depth),
              refreshCallback: async (formInfo: FormInfoInterface) => {
                if(!formInfo.formMeta) return
                const result = await formInfo.formMeta.loadCategories()
                this.vm?.$store.commit(`form/${this.formKey}/setMetaProperty`, {
                  key: 'categories',
                  value: !Array.isArray(result.nodes) ? [] : result.nodes,
                })
              },
              optionTextKey: 'name',
              optionValueKey: 'id',
              multiple: true,
              returnObject: true,
              options: (formInfo: FormInfoInterface) => {
                if(!formInfo.formMeta) return []
                if(!Array.isArray(formInfo.formMeta.categories)) return []
                return formInfo.formMeta.categories
              },
              hasChanged: ({originalData, data, bothEmpty, arrayPropertyDiff}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return arrayPropertyDiff(originalData, data, 'id')
              },
            },
            brief: {
              label: 'page.data.brief',
              type: 'textarea',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            photo: {
              label: 'data.photo',
              type: 'photo',
              photoType: 'page',
              photoManager: true,
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            photo_contain: {
              label: 'page.data.photo_contain',
              help: 'page.data.photo_contain.help',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            sitemap: {
              label: 'page.data.sitemap',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            content: {
              label: 'data.content',
              type: 'html',
              uploadOnPaste: true,
              uploadOnPasteMultiple: true,
              grid: { xl: 12, lg: 12, md: 12 },
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
          },
        },

        // 發布設定
        {
          title: 'form_block.publish',
          data: {
            status: {
              label: 'data.status',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
              grid: { xl: 12, lg: 12, md: 12},
            },
            locked: {
              label: 'page.data.locked',
              type: 'switch',
              hasChanged: ({originalData, data}: FormDataChangeHelperInterface) => {
                return originalData != data
              },
            },
            p: {
              label: 'page.data.password',
              help: 'page.data.password.help',
              type: 'text',
              titleSlot: () => import('@/modules/page/views/pageForm/copySecretLink.vue'),
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            publish_start: {
              label: 'page.data.publish_start',
              type: 'time',
            },
            publish_end: {
              label: 'page.data.publish_end',
              type: 'time',
            },
          },
        },

        // 其牠資訊
        {
          title: 'form_block.others',
          create: ({ formMode }: FormInfoInterface) => formMode === 'update',
          data: {
            created_at: {
              label: 'data.created_at',
              type: 'time',
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'time',
              readonly: true,
            },
            file: {
              label: 'data.file',
              type: 'file',
              maxQuantity: 5,
              fileType: 'page',
              hasChanged: ({originalData, data, bothEmpty}: FormDataChangeHelperInterface) => {
                if(bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
          },
        },
      ],

      metaAction: {
        preview: {
          label: 'action.preview',
          color: 'green',
          buttonProperties: () => ({ class: 'white--text' }),
          href: (formInfo: FormInfoInterface) => {
            const formData = formInfo.formData
            if(!formData) return null
            if(formInfo.formData.status == true) {
              // @ts-ignore
              return `${this.vm.frontendDomain}/page/${formData.type}/${formData.code}`
            }
            // @ts-ignore
            return `${this.vm.frontendDomain}/secret-page/${formData.id}?p=${formData.p}`
          },
          linkTarget: '_blank',
        },
      }
    }
  }
}

export default new formConfig()
